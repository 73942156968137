<template>
  <div class="df-width recruit">
    <div class="recruit-title">招贤纳士</div>
    <div class="recruit-desc">我们需要你</div>
    <div class="recruit-item">
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">Java开发工程师</div>
      </div>
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">PHP开发工程师</div>
      </div>
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">前端开发工程师</div>
      </div>
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">产品经理</div>
      </div>
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">运营专员</div>
      </div>
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">UI设计师</div>
      </div>
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">产品销售</div>
      </div>
      <div class="recruit-item-box" :style="{backgroundImage: 'url(' + recruit + ')'}">
        <div class="recruit-item-txt">测试专员</div>
      </div>
    </div>
<!--    <div class="recruit-label">-->
<!--      <div class="recruit-label-txt">岗位职责</div>-->
<!--      <div class="recruit-label-content">1. 独立完成系统模块的设计和编码；</div>-->
<!--      <div class="recruit-label-content">2. 负责疑难问题排查；</div>-->
<!--      <div class="recruit-label-content">3. 负责代码质量审核及性能优化。</div>-->
<!--    </div>-->
<!--    <div class="recruit-label">-->
<!--      <div class="recruit-label-txt">岗位要求</div>-->
<!--      <div class="recruit-label-content">1、5年及以上java平台开发工作经验；</div>-->
<!--      <div class="recruit-label-content">2、精通软件开发流程，熟悉主流设计模式；</div>-->
<!--      <div class="recruit-label-content">3、对技术发展具有良好的理解能力，对信息技术领域的新信息、新知识具有相当的敏感性、能够迅速更新自身的知识内容，具备良好的创新意识；</div>-->
<!--      <div class="recruit-label-content">4、热衷于技术，有创新激情，有良好的学习能力和团队合作精神；</div>-->
<!--      <div class="recruit-label-content">5、熟练使用Springboot、Spring cloud、MyBatis等主流开发框架</div>-->
<!--    </div>-->
<!--    <div class="recruit-line"></div>-->
<!--    <div class="recruit-label">-->
<!--      <div class="recruit-label-txt">福利待遇</div>-->
<!--      <div class="recruit-label-content">1、正常的做五休二工作制，享受法定的节假日和带薪年假。</div>-->
<!--      <div class="recruit-label-content">2、一流的办公环境+五险一金+年终奖</div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'Recruit',
  data () {
    return {
      recruit: require('@/assets/images/recruit.png')
    }
  }
}
</script>

<style scoped>
  .recruit {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .recruit-title {
    text-align: center;
    color: var(--color-text);
    font-size: 48px;
  }
  .recruit-desc {
    text-align: center;
    color: var(--color-text);
    font-size: 16px;
    margin: 20px 0 60px;
  }
  .recruit-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .recruit-item-box {
    width: 285px;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .recruit-item-box:hover, .recruit-item-box.active {
    background-image: none !important;
    background-color: #C81125;
  }
  .recruit-item-box:nth-child(4) {
    margin-right: 0;
  }
  .recruit-item-txt {
    color: #fff;
    font-size: 16px;
  }
  .recruit-label {
    margin-top: 40px;
  }
  .recruit-label-txt {
    color: var(--color-text);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .recruit-label-content {
    color: var(--color-text);
    font-size: 14px;
    margin-top: 8px;
  }
  .recruit-line {
    border-top: 1px solid #e3e6e4;
    margin-top: 40px;
  }
</style>
